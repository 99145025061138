<template>
  <div>
    <div class="wrp p-4 bg-white">
      <div class="d-flex mb-3 gap-2 child-lh-34">
        <p
          @click="backToListCircle"
          class="fs-24 fw-600 txt-344054 ml-3 mb-0 cursor-pointer"
        >{{ $t("conference.lbl_conference") }}</p>
        <span>/</span>
        <p class="fs-16 txt-667085 mr-2 mb-0">{{ $t("conference.lbl_member_list") }}</p>
      </div>
      <div class="d-flex mb-3 gap-2 items-center ml-3">
        <svg
          class="cursor-pointer"
          @click="backToListCircle"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.41406 12.657L12.3641 16.607C12.5417 16.7964 12.6387 17.0475 12.6345 17.3072C12.6303 17.5669 12.5252 17.8148 12.3415 17.9983C12.1578 18.1819 11.9098 18.2868 11.6501 18.2909C11.3905 18.2949 11.1394 18.1977 10.9501 18.02L5.29306 12.364C5.19987 12.2713 5.12592 12.1611 5.07546 12.0398C5.025 11.9185 4.99902 11.7884 4.99902 11.657C4.99902 11.5256 5.025 11.3954 5.07546 11.2741C5.12592 11.1528 5.19987 11.0426 5.29306 10.95L10.9501 5.29296C11.0423 5.19745 11.1526 5.12127 11.2747 5.06886C11.3967 5.01645 11.5279 4.98886 11.6607 4.98771C11.7934 4.98655 11.9251 5.01186 12.048 5.06214C12.1709 5.11242 12.2826 5.18667 12.3765 5.28056C12.4703 5.37446 12.5446 5.48611 12.5949 5.60901C12.6452 5.7319 12.6705 5.86358 12.6693 5.99636C12.6682 6.12914 12.6406 6.26036 12.5882 6.38236C12.5358 6.50437 12.4596 6.61471 12.3641 6.70696L8.41406 10.657H18.0001C18.2653 10.657 18.5196 10.7623 18.7072 10.9499C18.8947 11.1374 19.0001 11.3917 19.0001 11.657C19.0001 11.9222 18.8947 12.1765 18.7072 12.3641C18.5196 12.5516 18.2653 12.657 18.0001 12.657H8.41406Z"
            fill="black"
          />
        </svg>
        <p class="fs-24 fw-600 txt-black mb-0">{{ $t("conference.lbl_member_list") }}</p>
      </div>
      <div class="ml-3">
        <nav class="navbar navbar-expand-lg navbar-light bg-white">
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li
                class="nav-item"
                :class="
                  type === CONFERENCE_LIST_MEMBERS_TYPE.reporter ? 'active' : ''
                "
              >
                <a
                  class="nav-link fs-14"
                  href="javascript:;"
                  @click="handleChangeType(CONFERENCE_LIST_MEMBERS_TYPE.reporter)"
                >
                  {{ $t("conference.lbl_speaker") }} ({{
                  pagination[1].total || 0
                  }})
                </a>
              </li>
              <li
                class="nav-item"
                :class="type === CONFERENCE_LIST_MEMBERS_TYPE.member ? 'active' : ''"
              >
                <a
                  class="nav-link fs-14"
                  href="javascript:;"
                  @click="handleChangeType(CONFERENCE_LIST_MEMBERS_TYPE.member)"
                >
                  {{ $t("conference.lbl_guest") }} ({{
                  pagination[0].total || 0
                  }})
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div :set="(mbs = membersData[type-1])" class="table-responsive">
          <table
            class="table table-vcenter table-mobile-md card-table no--border table-patient-list"
          >
            <thead>
              <tr>
                <th style="width: 30%">
                  <span class="txt-grey-600 fs-14 fw-600 whitespace-nowrap">
                    {{
                    $t("circle_feature.lbl_information")
                    }}
                  </span>
                </th>
                <th style="width: 30%">
                  <span class="txt-grey-600 fs-14 fw-600 whitespace-nowrap">
                    {{
                    $t("circle_feature.lbl_position")
                    }}
                  </span>
                </th>
                <th style="width: 30%">
                  <span class="txt-grey-600 fs-14 fw-600 whitespace-nowrap">
                    {{
                    $t("circle_feature.lbl_workplace")
                    }}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="member in handleFormatMemberData(mbs)"
                class="cursor-pointer custom"
                :key="member.id"
              >
                <td
                  :data-label="$t('circle_feature.lbl_information')"
                  @click="
                    goToProfile(
                      member.user_infor
                    )
                  "
                >
                  <div class="px-3 flex items-center w-full">
                    <div class="mr-2">
                      <ImageBox
                        v-if="member.user_infor.avatar"
                        class="avatar avatar-rounded mr-0 object-cover object-top border"
                        avatar
                        :source="getImageURL(member.user_infor.avatar)"
                        :alt="type === CONFERENCE_LIST_MEMBERS_TYPE.reporter ? (member.langData && member.langData.name
                        ? member.langData.name
                        : '') : (member.name ? member.name : '')"
                      />
                      <span v-if="!member.user_infor.avatar" class="avatar avatar-rounded mr-0">
                        {{
                        type === CONFERENCE_LIST_MEMBERS_TYPE.reporter ? (member.langData&&
                        member.langData.name
                        ? member.langData.name.charAt(0)
                        : member.name.charAt(0)) : (member.name ? member.name.charAt(0) : "")
                        }}
                      </span>
                    </div>
                    <div class="flex-1">
                      <p class="mb-0 fs-14 fw-600">
                        {{ type === CONFERENCE_LIST_MEMBERS_TYPE.reporter
                        ? (member.langData&&member.langData.name ? member.langData.name : member.name)
                        : member.name }}
                      </p>
                    </div>
                  </div>
                </td>

                <td :data-label="$t('circle_feature.lbl_position')">
                  <p class="mb-0 fs-14 fw-500">
                    {{
                    type === CONFERENCE_LIST_MEMBERS_TYPE.reporter
                    ? (member.langData&&member.langData.job_title ? member.langData.job_title : member.job_title)
                    : (member.job_title)
                    }}
                  </p>
                </td>
                <td :data-label="$t('circle_feature.lbl_workplace')">
                  <p class="mb-0 fs-14 fw-500">
                    {{
                    type === CONFERENCE_LIST_MEMBERS_TYPE.reporter
                    ? (member.langData&&member.langData.workplace ? member.langData.workplace : member.workplace)
                    : (member.workplace)
                    }}
                  </p>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td colspan="4" class="text-center">
                  <a
                    href="javascript:;"
                    class="txt-pri fs-16"
                    v-if="pagination[type-1].page_num < pagination[type-1].total_page"
                    @click="handleLoadMore"
                  >{{ $t("circle_feature.btn_load_more") }}</a>
                  <div class="text-center" v-if="!mbs && !isLoading">
                    <p class="fs-16 fw-500">{{ $t("circle_feature.lbl_no_member") }}</p>
                  </div>
                  <!-- <div class="skeleton-line" v-if="isLoading"></div> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { LANGUAGE_CODES_FOR_DISEASE } from '@/lang'
import { CONFERENCE_LIST_MEMBERS_TYPE } from '@/utils/constants'
import appUtils from '@/utils/appUtils'
import { Back } from '@/components/Icon'
import { LANGUAGE_CODES_FOR_DISEASE } from '@/lang'
import { isArray } from 'lodash'
import ImageBox from '@/components/Common/Image.vue'

export default {
  name: 'ConferenceListMember',
  components: { Back, ImageBox },
  data () {
    return {
      type: CONFERENCE_LIST_MEMBERS_TYPE.reporter,
      membersData: [{}, {}],
      isLoading: false,
      CONFERENCE_LIST_MEMBERS_TYPE,
      pagination: [
        {
          page_num: 1,
          page_size: 10,
          total: 0,
          total_page: 0
        },
        {
          page_num: 1,
          page_size: 10,
          total: 0,
          total_page: 0
        }
      ],
      scrollTop: 0,
      appUtils
    }
  },
  watch: {
    $route: {
      deep: true,
      handler () {
        this.getConferenceCircleMembers()
      }
    }
  },
  async created () {
    await this.getInitialData()
  },
  computed: {
    dataFormat () {
      const langValue = LANGUAGE_CODES_FOR_DISEASE[this.$lang.toUpperCase()]

      const newMembers = this.membersData?.map(memberArr => {
        const newMemberArr = (memberArr || [])?.map(item => {
          const langData =
            item?.user_infor?.speaker_multi_language?.find(
              speakerInfo => speakerInfo?.lang === langValue
            ) || {}
          return { ...item, langData }
        })
        return newMemberArr
      })

      return newMembers
    }
  },
  methods: {
    async getInitialData () {
      await this.getConferenceCircleMembers()
      this.type = CONFERENCE_LIST_MEMBERS_TYPE.member
      await this.getConferenceCircleMembers()
      this.type = CONFERENCE_LIST_MEMBERS_TYPE.reporter
    },
    goToProfile (data) {
      const doctorId = data?.doctor_infor?.id
      if (!doctorId) return
      this.$router
        .push({ name: 'DoctorProfile', params: { id: doctorId } })
        .catch(() => {})
    },
    getImageURL (a) {
      if (a?.includes('http')) return a

      return appUtils.getImageURL(a)
    },
    handleChangeType (type) {
      this.type = type
      this.getConferenceCircleMembers()
    },
    async getConferenceCircleMembers (isNext = false) {
      if (!this.$route.query?.conference_id) return

      try {
        this.isLoading = true
        const params = {
          conference_id: this.$route.query?.conference_id,
          registration_type: this.type,
          page_num: isNext ? this.pagination[this.type - 1].page_num + 1 : 1,
          page_size: this.pagination[this.type - 1].page_size,
          status: 2,
          sort_by: this.type === 2 ? 'highline_order' : 'id',
          order: this.type === 2 ? 'asc' : 'desc'
        }

        const response = await this.$rf
          .getRequest('ConferenceRequest')
          .getAllConferenceRegistrants(params)
        const responseData = response.data || {}
        this.pagination[this.type - 1] = responseData.page
        if (!isNext) {
          this.membersData[this.type - 1] = responseData.data
        } else {
          if (responseData?.page.total) {
            let newObj = responseData.data
            this.membersData = this.membersData?.map((item, index) => {
              if (index === this.type - 1) {
                return item.concat(newObj)
              } else {
                return item
              }
            })
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async handleLoadMore (e) {
      const self = this
      self.scrollTop += e.pageY
      const isNext = true
      await self.getConferenceCircleMembers(isNext).then(() => {
        document.getElementsByClassName('wrp')[0].scrollTop = self.scrollTop
      })
    },
    backToListCircle () {
      this.$router.push({ path: `/conference` })
    },
    handleFormatMemberData (data) {
      if (!isArray(data)) return {}
      const langValue = LANGUAGE_CODES_FOR_DISEASE[this.$lang.toUpperCase()]

      const newMembers = data?.map(item => {
        const langData =
          item?.user_infor?.speaker_multi_language?.find(
            speakerInfo => speakerInfo?.lang === langValue
          ) || {}
        return { ...item, langData }
      })

      return newMembers
    }
  }
}
</script>

<style lang="scss" scoped>
.wrp {
  color: black;
  background-color: #f7f6f6;
  height: calc(100vh - 61px);
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.txt-667085 {
  color: #667085;
}
.txt-344054 {
  color: #344054;
}
.child-lh-34 {
  * {
    line-height: 34px;
  }
}
</style>
